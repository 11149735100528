import React from 'react';
import IO from 'components/io';
import { IntroText } from 'components/intro/intro.css';
import { Container } from './io-headline.css';

// <IO> uses a render prop to pass down `isVisible` and `hasBeenVisible`.
// In this example, we only care about `isVisible` and reset the styles
// every time we scroll back up. Use `hasBeenVisible` to keep the styles
// after scrolling back up and down again.
const IOHeadline = ({ children, as, site, home }) => (
  <IO rootMargin="0px">
    {({ isVisible }) => (
      <Container isVisible={isVisible}>
        <IntroText home={home} as={as}>
          {children}
        </IntroText>
      </Container>
    )}
  </IO>
);

export default IOHeadline;
