import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const IntroText = styled.span`
  display: block;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 400;
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'huge':
        return '3.2rem';
      case 'large':
        return '2.8rem';
      default:
        return '2.2rem';
    }
  }};
  line-height: 1.7;

  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'huge':
          return '2.6rem';
        case 'large':
          return '1.2rem';
        default:
          return '1.2rem';
      }
    }};
  `};
  ${props =>
    props.home &&
    css`
      font-size: 1.2rem;
      color: grey;
      line-height: 1.4;
    `}
`;
export const Intro = styled.div`
  display: block;
  //padding: 1rem 3rem 6rem;
  mix-blend-mode: difference;

  ${MEDIA.MIN_TABLET`
  max-width: 60vw;
  `};
`;
